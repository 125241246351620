import {
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import Link from 'next/link';
import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay
} from 'swiper';
import {
  Swiper,
  SwiperSlide
} from 'swiper/react';
import { Image } from '@/components/Image';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/bundle';
import { BannersQuery } from '@/graphql/generated/graphql';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const swiperStyles = {
  '.swiper-button-prev': {
    color: 'brandSecondary.500',
  },
  '.swiper-button-prev:after': {
    textStyle: 'h1'
  },
  '.swiper-button-next': {
    color: 'brandSecondary.500',
  },
  '.swiper-button-next:after': {
    textStyle: 'h1'
  },
  '.swiper-pagination': {
    textAlign: 'right'
  },
  '.swiper-pagination-bullet': {
    bg: 'brandSecondary.500'
  },
};

type TopBannerSwiperProps = {
  banners: BannersQuery['banners'];
}

const dummyBanner: BannersQuery['banners'] = [
{
  id: 1,
  altText: 'reraku',
  spImage: '/images/course_category.jpeg',
  pcImage: '/images/dummy_banner.png',
  imageLink: '',
}];

export const TopBannerSwiper = ({
  banners
}: TopBannerSwiperProps) => {
  const displayBanners = banners.length > 0 ? banners : dummyBanner;

  return (
    <Flex
      sx={swiperStyles}
    >
      <Box
        bg={'brand.100'}
        px={'5%'}
        display={{ base: 'none', lg: 'block' }}
        mb={1.5}
      >
        <Box
          as={'h2'}
          mt={{ lg: '100px', xl: '90px' }}
          width={{ lg: 120, xl: '210px' }}
          height={{ lg: 120, xl: '210px' }}
        >
          <Box
            position={'relative'}
            mx={'auto'}
            width={{ lg: 120, xl: '210px' }}
            height={{ lg: 120, xl: '210px' }}
          >
            <Image
              src={'/images/top/top_page_title.png'}
              alt={'人の一生を健康に'}
              objectFit={'contain'}
              layout={'fill'}
            />
          </Box>
        </Box>
        <Box
          width={{ lg: 120, xl: '210px' }}
          mt={8}
        >
          <Text
            color={'deepGreen.500'}
            textAlign={'justify'}
            textStyle={'h6'}
          >
            リラクグループでは、コミュニケーションとボディケアを通じて、活力ある毎日を送るためのサポートをしています。
          </Text>
        </Box>
      </Box>
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        navigation
        autoplay={displayBanners.length > 1 ? { delay: 7000 } : false}
        loop={true}
      >
        {displayBanners.map(({
          altText,
          spImage,
          pcImage,
          imageLink,
        }) =>
          <SwiperSlide
            key={altText}
          >
            <Link
              href={imageLink}
              passHref={true}
            >
              <Box
                as={'a'}
                display={{ base: 'block', md: 'none' }}
              >
                <Image
                  src={spImage}
                  alt={altText}
                  width={900}
                  height={900}
                />
              </Box>
            </Link>
            <Link
              href={imageLink}
              passHref={true}
            >
              <Box
                display={{ base: 'none', md: 'block' }}
                height={'auto'}
                as={'a'}
              >
                <Image
                  src={pcImage}
                  alt={altText}
                  width={1280}
                  height={720}
                />
              </Box>
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
    </Flex>
  );
};
