import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next';
import { MainLayout } from '@/components';
import { getPageInfo } from '@/config/pages';
import { TopPage } from '@/features/top/components/pages/TopPage/TopPage';
import {
  BannersQuery,
  CustomerInformationsQuery,
  ShopSearchConditionsQuery,
  useBannersQuery,
  useCustomerInformationsQuery,
  useShopSearchConditionsQuery,
} from '@/graphql/generated/graphql';
import { NextPage } from '@/types/nextjs';
import { buildEmptyPageInfo } from '@/utils/pageInfo';

type Props = InferGetServerSidePropsType<typeof getServerSideProps>;

const Page: NextPage<Props> = ({
  customerInformations,
  shopSearchConditions,
  banners,
}: Props) => {
  return (
    <TopPage
      shopSearchConditions={shopSearchConditions}
      customerInformations={customerInformations}
      banners={banners}
    />
  );
};

Page.getLayout = (page) => (
  <MainLayout
    metaTagInfo={{
      title: 'リラクゼーションサロン',
      canonicalUrl: getPageInfo('top', {}, { withFullUrl: true }).href,
    }}
    isShowBreadcrumb={false}
    og={{
      'og:title': 'リラクゼーションサロン'
    }}
  >
    {page}
  </MainLayout>
);

type ServerProps = {
  shopSearchConditions: ShopSearchConditionsQuery['shopSearchConditions'];
  customerInformations: CustomerInformationsQuery['customerInformations'];
  banners: BannersQuery['banners'];
}

export const getServerSideProps: GetServerSideProps<ServerProps> = async (_ctx: GetServerSidePropsContext) => {
  const shopSearchConditionData = await useShopSearchConditionsQuery.fetcher({
    skipLoadDataCities: true,
    skipLoadDataRailroadLines: true
  })();
  const bannersData = await useBannersQuery.fetcher()();

  let informationData: CustomerInformationsQuery = {
    customerInformations: {
      items: [],
      pageInfo: buildEmptyPageInfo(),
    }
  };

  try {
    // WPが落ちていてもサイトは利用できるようにする
    informationData = await useCustomerInformationsQuery.fetcher({ page: 1, per: 2 })();
  } catch {
    // TODO: ログ出力
  }

  if (!informationData.customerInformations) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      shopSearchConditions: shopSearchConditionData.shopSearchConditions,
      customerInformations: informationData.customerInformations,
      banners: bannersData.banners,
    }
  };
};

export default Page;
