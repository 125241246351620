import {
  Text,
  Flex,
  Box,
  Grid,
  HStack,
  GridItem,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import Link from 'next/link';
import { JapanMap } from '../../ui/JapanMap/JapanMap';
import { MovieArea } from '../../ui/MovieArea/MovieArea';
import { ShopSearchFixedFooter } from '../../ui/ShopSearchFixedFooter/ShopSearchFixedFooter';
import { PcShopSearchForm } from '../../ui/ShopSearchForm/PcShopSearchForm';
import { TopBannerSwiper } from '../../ui/Swiper/TopBannerSwiper';
import {
  AppStoreLink,
  navbarHeight,
  PcDisplayBlock,
  SpDisplayBlock,
} from '@/components';
import { HeadingImage } from '@/components/HeadingImage/HeadingImage';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { getPageInfo } from '@/config/pages';
import { ListItem } from '@/features/customerInformation/components/ui/List/ListItem';
import { useShopSearch } from '@/features/shops/components/hooks/useShopSearch';
import { PcShopSearchModal } from '@/features/shops/components/ui/ShopSearch/Pc/PcShopSearchModal';
import {
  CustomerInformations,
  ShopSearchConditions
} from '@/features/shops/types/shopSearch';
import { BannersQuery } from '@/graphql/generated/graphql';
import { defaultShopSearchParams } from '@/types/shopSearch';

export type TopPageProps = {
  shopSearchConditions: ShopSearchConditions;
  customerInformations: CustomerInformations;
  banners: BannersQuery['banners'];
}

export const TopPage = ({
  shopSearchConditions,
  customerInformations,
  banners,
}: TopPageProps) => {

  const shopSearch = useShopSearch({
    defaultShopSearchParams: defaultShopSearchParams,
    defaultShopSearchConditions: shopSearchConditions,
  });

  const isPc = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        textStyle={'h10'}
        mt={-5}
        as={'h1'}
      >
        Re. Ra. Ku
      </Box>

      {/* top view */}
      <PcDisplayBlock
        px={2}
        pt={2}
        pb={4}
        position={'sticky'}
        zIndex={'sticky'}
        top={`${navbarHeight + 10}px`}
        boxShadow={'md'}
        w={'100vw'}
        ml={'calc(50% - 50vw)'}
        bg={isOpen ? 'white' : 'lightGreen.500'}
      >
        {/* モーダルが開いている間は検索フォームを描画しない(モーダルのCSRが動かなくなるため) */}
        {!isOpen &&
          <PcShopSearchForm
            shopSearch={shopSearch}
            onOpen={onOpen}
            onClose={onClose}
          />
        }
        {isPc &&
          <PcShopSearchModal
            isOpen={isOpen}
            onClose={onClose}
            shopSearch={shopSearch}
          />
        }
      </PcDisplayBlock>
      <TopBannerSwiper
        banners={banners}
      />

      <Box
        bgImage={'/images/background.jpg'}
        backgroundRepeat={'repeat'}
        mt={-2}
      >
        <Box
          mx={{ base: 8, lg: 10 }}
          pt={{ base: 2, lg: 12 }}
        >
          {/* brand見出し、reraku */}

          <Grid
            templateColumns={{ base: '1fr', lg: '1fr 2fr' }}
            borderBottom={{ lg: '2px solid' }}
            borderColor={{ lg: 'rgba(85, 62, 38, 0.3)' }}
            py={3}
          >
            <Box
              px={{ lg: 10 }}
            >
              <SpDisplayBlock>
                <Box
                  w={{ base: '70%', md: '50%' }}
                  position={'relative'}
                  as={'h2'}
                >
                  <Image
                    src={'/images/top/toppage_title_sp.png'}
                    alt={'人の一生を健康に'}
                    width={933}
                    height={347}
                  />
                </Box>
                <Text
                  textAlign={'justify'}
                  textStyle={'h5'}
                  pt={4}
                  pb={4}
                  borderBottom={'2px solid rgba(85, 62, 38, 0.3)'}
                >
                  リラクグループでは、コミュニケーションとボディケアを通じて、活力ある毎日を送るためのサポートをしています。
                </Text>
              </SpDisplayBlock>
              <Box
                w={{ base: '200px', xl: '250px' }}
                h={20}
                position={'relative'}
                as={'h2'}
              >
                <Image
                  src={'/images/top/heading/brand.png'}
                  alt={'ブランド'}
                  layout={'fill'}
                  objectFit={'contain'}
                />
              </Box>
              <Text
                textAlign={'justify'}
                textStyle={'h5'}
              >
                『 Re.Ra.Ku 』と『 Spa Re.Ra.Ku 』を中心に、
                健康管理を目的としたブランドを全国に展開しています。
                一人ひとりのお疲れに合わせた施術とコミュニケーションを
                通してこれまで約200万人のお客様の健康管理のサポートをしてきました。
              </Text>
              <SpDisplayBlock
                mb={6}
                borderBottom={{ base: '2px solid', lg: 'none' }}
                borderColor={'rgba(85, 62, 38, 0.3)'}
              >
                <MovieArea/>
              </SpDisplayBlock>
              <Box
                mx={'auto'}
                display={{ base: 'none', lg: 'block' }}
                py={4}
              >
                <LinkButton
                  display={'block'}
                  width={'80%'}
                  text={'店舗検索'}
                  linkUrl={getPageInfo('studio', {})}
                  my={{ base: 4, lg: 6 }}
                  mx={{ base: 'auto', lg: 0 }}
                />
              </Box>
            </Box>
            <Box
              px={{ base: 0, lg: 10 }}
              borderLeft={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
              borderBottom={{ base: '2px solid rgba(85, 62, 38, 0.3)', lg: '0px' }}
              pb={{ base: 3, lg: 0 }}
              mb={{ base: 4, lg: 0 }}
            >
              <Box>
                <Box
                  display={'flex'}
                  flexDirection={{ base: 'row-reverse', lg: 'row' }}
                  justifyContent={{ base: 'space-between', lg: 'flex-start' }}
                  alignItems={'center'}
                  pt={{ lg: 6 }}
                >
                  <Box
                    position={'relative'}
                    w={{ base: '60px', lg: 20 }}
                    h={{ base: '60px', lg: 20 }}
                  >
                    <Image
                      src={'/images/top/number/01.png'}
                      alt={'01'}
                      layout={'fill'}
                      objectFit={'contain'}
                    />
                  </Box>
                  <Box
                    display={{ base: 'block', lg: 'flex' }}
                    alignItems={'center'}
                  >
                    <Text
                      as={'h3'}
                      fontWeight={'bold'}
                      fontSize={'26px'}
                      color={'brand.500'}
                      pl={{ base: 0, lg: 2 }}
                    >
                      Re.Ra.Ku
                    </Text>
                    <Text
                      as={'h3'}
                      fontWeight={'bold'}
                      fontSize={'15px'}
                      color={'brand.500'}
                      pl={{ base: 0, lg: 8 }}
                    >
                      コミュニケーション &times; ボディケア
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={{ base: 'column-reverse', lg: 'row' }}
              >
                <Link
                  href={getPageInfo('brand/reraku', {}).href}
                  passHref={true}
                >
                  <Box
                    w={{ base: '100%', lg: '45%' }}
                    as={'a'}
                  >
                    <Image
                      src={'/images/top/store/reraku_store.jpg'}
                      alt={'Re.Ra.Ku 店舗'}
                      width={900}
                      height={600}
                    />
                  </Box>
                </Link>
                <Box
                  w={{ base: '100%', lg: '55%' }}
                  pb={4}
                >
                  <Text
                    textAlign={'justify'}
                    pl={{ lg: 10 }}
                    pt={'6px'}
                    textStyle={'h5'}
                  >
                    メインブランドのRe.Ra.Ku では、ウィングストレッチ&reg;（肩甲骨はがし）を取り入れたリラク系ボディケアを提供しています。東京近郊を中心に190店舗以上を展開しています。
                  </Text>
                  <PcDisplayBlock>
                    <HStack
                      pl={{ lg: 10 }}
                      pt={14}
                      alignItems={'baseline'}
                    >
                      <Box
                        w={10}
                        h={10}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/labels/body_care.png'}
                          alt={'ボディケア'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                      <Box
                        w={10}
                        h={10}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/labels/footcare.png'}
                          alt={'フットケア'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                      <Box
                        w={10}
                        h={10}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/labels/thailand.png'}
                          alt={'タイ古式マッサージ'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                      <Box
                        w={10}
                        h={10}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/labels/aroma.png'}
                          alt={'アロマ'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                      <Flex
                        flex={1}
                        justify={'flex-end'}
                      >
                        <Link
                          href={getPageInfo('brand/reraku', {}).href}
                          passHref={true}
                        >
                          <Box
                            as={'a'}
                            _hover={{
                              textDecoration: 'none',
                              opacity: 0.8,
                              cursor: 'pointer'
                            }}
                            w={'40px'}
                            h={'60px'}
                            position={'relative'}
                          >
                            <Image
                              src={'/images/top/pc/more_reraku.png'}
                              alt={'もっと見る'}
                              layout={'fill'}
                              objectFit={'contain'}
                            />
                          </Box>
                        </Link>
                      </Flex>
                    </HStack>
                  </PcDisplayBlock>
                </Box>
              </Box>
              <SpDisplayBlock>
                <Flex
                  flex={1}
                  justify={'flex-end'}
                >
                  <Link
                    href={getPageInfo('brand/reraku', {}).href}
                    passHref={true}
                  >
                    <Box
                      as={'a'}
                      _hover={{
                        textDecoration: 'none',
                        opacity: 0.8,
                        cursor: 'pointer'
                      }}
                      w={'100px'}
                      h={'50px'}
                      position={'relative'}
                    >
                      <Image
                        src={'/images/top/sp/more_reraku.png'}
                        alt={'もっと見る'}
                        layout={'fill'}
                        objectFit={'contain'}
                      />
                    </Box>
                  </Link>
                </Flex>
              </SpDisplayBlock>
            </Box>
          </Grid>

          {/* spa pro bell ruam movie */}
          <Grid
            templateAreas={{
              base:
                `"spa"
              "pro"
              "bel"
              "rua"
              "mov"`,
              lg:
                `"spa spa pro"
              "spa spa pro"
              "bel bel pro"
              "bel bel rua"
              "mov mov rua"
              "mov mov rua"`
            }}
            gridTemplateColumns={{ base: '1fr', lg: '2fr 1fr' }}
            borderBottom={{ lg: '2px solid rgba(85, 62, 38, 0.3' }}
            pb={{ lg: 4 }}
            gap={2}
            placeContent={'center'}
            placeItems={'center'}
          >
            <GridItem
              area={'spa'}
              borderBottom={'2px solid rgba(85, 62, 38, 0.3)'}
              pb={{ base: 3, lg: 0 }}
              mb={{ base: 6, lg: 0 }}
              mx={{ lg: 3 }}
            >
              <Box
                pt={{ base: 0, lg: 3 }}
                mt={{ base: 0, lg: 4 }}
                pl={{ base: 0, lg: 10 }}
                pr={{ base: 0, lg: 5 }}
              >
                <Box>
                  <Box
                    display={'flex'}
                    flexDirection={{ base: 'row-reverse', lg: 'row' }}
                    justifyContent={{ base: 'space-between', lg: 'flex-start' }}
                    alignItems={'center'}
                  >
                    <Box
                      position={'relative'}
                      w={{ base: '60px', lg: 20 }}
                      h={{ base: '60px', lg: 20 }}
                    >
                      <Image
                        src={'/images/top/number/02.png'}
                        alt={'02'}
                        layout={'fill'}
                        objectFit={'contain'}
                      />
                    </Box>
                    <Box
                      display={{ base: 'block', lg: 'flex' }}
                      alignItems={'center'}
                    >
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'26px'}
                        color={'sparerakuBrand.500'}
                        pl={{ base: 0, lg: 2 }}
                      >
                        Spa Re.Ra.Ku
                      </Text>
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'16px'}
                        color={'sparerakuBrand.500'}
                        pl={{ base: 0, lg: 8 }}
                      >
                        お風呂 &times; ボディケア
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={{ base: 'column-reverse', lg: 'row' }}
                >
                  <Link
                    href={getPageInfo('brand/spareraku', {}).href}
                    passHref={true}
                  >
                    <Box
                      w={{ base: '100%', lg: 400 }}
                      as={'a'}
                    >
                      <Image
                        src={'/images/top/store/spa_store.jpg'}
                        alt={'Spa Re.Ra.Ku 店舗'}
                        width={900}
                        height={600}
                      />
                    </Box>
                  </Link>
                  <Box
                    w={{ base: '100%', lg: '55%' }}
                  >
                    <Text
                      textAlign={'justify'}
                      pl={{ lg: 10 }}
                      pb={4}
                      textStyle={'h5'}
                      pt={'6px'}
                    >
                      全国の温浴施設に展開しています。心と身体の癒しはもちろん、温浴施設の環境を活かした美容やダイエットなどを含んだトータルケアでお客様の美と健康管理をサポートします。
                    </Text>
                    <PcDisplayBlock>
                      <HStack
                        pl={{ lg: 10 }}
                        pt={8}
                        alignItems={'baseline'}
                      >
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/body_care.png'}
                            alt={'ボディケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/footcare.png'}
                            alt={'フットケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/thailand.png'}
                            alt={'タイ古式マッサージ'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/aroma.png'}
                            alt={'アロマ'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Flex
                          flex={1}
                          justify={'flex-end'}
                        >
                          <Link
                            href={getPageInfo('brand/spareraku', {}).href}
                            passHref={true}
                          >
                            <Box
                              as={'a'}
                              _hover={{
                                textDecoration: 'none',
                                opacity: 0.8,
                                cursor: 'pointer'
                              }}
                              w={'40px'}
                              h={'60px'}
                              position={'relative'}
                            >
                              <Image
                                src={'/images/top/pc/more_spa.png'}
                                alt={'もっと見る'}
                                layout={'fill'}
                                objectFit={'contain'}
                              />
                            </Box>
                          </Link>
                        </Flex>
                      </HStack>
                    </PcDisplayBlock>
                  </Box>
                </Box>
                <SpDisplayBlock>
                  <Flex
                    flex={1}
                    justify={'flex-end'}
                  >
                    <Link
                      href={getPageInfo('brand/spareraku', {}).href}
                      passHref={true}
                    >
                      <Box
                        as={'a'}
                        _hover={{
                          textDecoration: 'none',
                          opacity: 0.8,
                          cursor: 'pointer'
                        }}
                        w={'100px'}
                        h={'50px'}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/sp/more_spa.png'}
                          alt={'もっと見る'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                    </Link>
                  </Flex>
                </SpDisplayBlock>
              </Box>
            </GridItem>
            <GridItem
              area={'pro'}
              borderLeft={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
              borderBottom={{ base: '2px solid rgba(85, 62, 38, 0.3)', lg: 'none' }}
              pt={{ lg: 3 }}
              pb={{ base: 3, lg: 4 }}
              mb={{ base: 6, lg: 0 }}
              mt={{ lg: 4 }}
            >
              <Flex
                flexDirection={'column'}
                px={{ lg: 5 }}
              >
                <Box>
                  <Box
                    display={'flex'}
                    flexDirection={{ base: 'row-reverse', lg: 'row' }}
                    justifyContent={{ base: 'space-between', lg: 'flex-start' }}
                    alignItems={'center'}
                  >
                    <Box
                      position={'relative'}
                      w={{ base: '60px', lg: 20 }}
                      h={{ base: '60px', lg: 20 }}
                    >
                      <Image
                        src={'/images/top/number/03.png'}
                        alt={'03'}
                        layout={'fill'}
                        objectFit={'contain'}
                      />
                    </Box>
                    <Box>
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'26px'}
                        color={'rerakuproBrandSecondary.300'}
                        pl={{ base: 0, lg: 2 }}
                      >
                        Re.Ra.Ku PRO
                      </Text>
                      <Text
                        as={'h3'}
                        pl={{ lg: 2 }}
                        fontWeight={'bold'}
                        fontSize={'16px'}
                        color={'rerakuproBrandSecondary.300'}
                      >
                        ランステ &times; ボディケア
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display={{ base: 'flex', lg: 'block' }}
                    flexDirection={'column-reverse'}
                  >
                    <Link
                      href={getPageInfo('brand/rerakupro', {}).href}
                      passHref={true}
                    >
                      <Box
                        as={'a'}
                      >
                        <Box
                          w={{ base: '100%', lg: 400 }}
                          pt={{ lg: 4 }}
                        >
                          <Image
                            src={'/images/top/store/pro_store.jpg'}
                            alt={'Re.Ra.Ku PRO 店舗'}
                            width={900}
                            height={600}
                          />
                        </Box>
                      </Box>
                    </Link>
                    <Box
                      w={{ lg: '400px' }}
                      pb={4}
                      pt={'6px'}
                    >
                      <Text
                        textAlign={'justify'}
                        textStyle={'h5'}
                      >
                        健康の根本解決を目指す 複合型ランニングステーションです。
                      </Text>

                      <Text
                        textAlign={'justify'}
                        textStyle={'h5'}
                      >
                        ランナー向けのボディケアや、管理栄養士が監修した カフェメニューの 提供を行っています。
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <PcDisplayBlock>
                      <HStack
                        alignItems={'baseline'}
                      >
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/body_care.png'}
                            alt={'ボディケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/running.png'}
                            alt={'ランニング'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>

                        <Flex
                          flex={1}
                          justify={'flex-end'}
                        >
                          <Link
                            href={getPageInfo('brand/rerakupro', {}).href}
                            passHref={true}
                          >
                            <Box
                              as={'a'}
                              _hover={{
                                textDecoration: 'none',
                                opacity: 0.8,
                                cursor: 'pointer'
                              }}
                              w={'40px'}
                              h={'60px'}
                              position={'relative'}
                            >
                              <Image
                                src={'/images/top/pc/more_pro.png'}
                                alt={'もっと見る'}
                                layout={'fill'}
                                objectFit={'contain'}
                              />
                            </Box>
                          </Link>
                        </Flex>
                      </HStack>
                    </PcDisplayBlock>
                  </Box>
                  <SpDisplayBlock>
                    <Flex
                      flex={1}
                      justify={'flex-end'}
                    >
                      <Link
                        href={getPageInfo('brand/rerakupro', {}).href}
                        passHref={true}
                      >
                        <Box
                          as={'a'}
                          _hover={{
                            textDecoration: 'none',
                            opacity: 0.8,
                            cursor: 'pointer'
                          }}
                          w={'100px'}
                          h={'50px'}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/sp/more_pro.png'}
                            alt={'もっと見る'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                      </Link>
                    </Flex>
                  </SpDisplayBlock>
                </Box>
              </Flex>
              <Box
                borderBottom={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
                position={'relative'}
                top={7}
                mx={3}
              />
            </GridItem>
            <GridItem
              area={'bel'}
              borderBottom={'2px solid rgba(85, 62, 38, 0.3)'}
              pb={{ base: 3, lg: 2 }}
              mb={{ base: 6, lg: 0 }}
              px={{ lg: 3 }}
              mr={{ base: 0, lg: 4 }}
            >
              <Box
                pt={{ base: 0, lg: 7 }}
                pl={{ base: 0, lg: 10 }}
                pr={{ base: 0, lg: 1 }}
              >
                <Box>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    flexDirection={{ base: 'row-reverse', lg: 'row' }}
                    justifyContent={{ base: 'space-between', lg: 'flex-start' }}
                  >
                    <Box
                      position={'relative'}
                      w={{ base: '60px', lg: 20 }}
                      h={{ base: '60px', lg: 20 }}
                    >
                      <Image
                        src={'/images/top/number/04.png'}
                        alt={'01'}
                        layout={'fill'}
                        objectFit={'contain'}
                      />
                    </Box>
                    <Box
                      display={{ base: 'block', lg: 'flex' }}
                      alignItems={'center'}
                    >
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'26px'}
                        color={'bellepocBrand.500'}
                        pl={{ base: 0, lg: 2 }}
                      >
                        Bell Epoc
                      </Text>
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'16px'}
                        color={'bellepocBrand.500'}
                        pl={{ base: 0, lg: 8 }}
                      >
                        アロマ &times; ボディケア
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={{ base: 'column-reverse', lg: 'row' }}
                >
                  <Link
                    href={getPageInfo('brand/bellepoc', {}).href}
                    passHref={true}
                  >
                    <Box
                      w={{ base: '100%', lg: 400 }}
                      as={'a'}
                    >
                      <Image
                        src={'/images/top/store/bell_store.jpg'}
                        alt={'BellEpoc 店舗'}
                        width={900}
                        height={600}
                      />
                    </Box>
                  </Link>
                  <Box
                    w={{ base: '100%', lg: '55%' }}
                  >
                    <Text
                      textAlign={'justify'}
                      pl={{ lg: 10 }}
                      textStyle={'h5'}
                      pb={4}
                      pt={'6px'}
                    >
                      地方を中心に、約35店舗を展開しています。ボディケアはもちろん、リフレクソロジーやアロマボディケアなどの豊富なメニューからお客様のお疲れに最適なコースをご提案します。
                    </Text>
                    <PcDisplayBlock>
                      <HStack
                        pl={{ lg: 10 }}
                        pt={8}
                        alignItems={'baseline'}
                      >
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/body_care.png'}
                            alt={'ボディケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/footcare.png'}
                            alt={'フットケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/aroma.png'}
                            alt={'アロマ'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Flex
                          flex={1}
                          justify={'flex-end'}
                        >
                          <Link
                            href={getPageInfo('brand/bellepoc', {}).href}
                            passHref={true}
                          >
                            <Box
                              as={'a'}
                              _hover={{
                                textDecoration: 'none',
                                opacity: 0.8,
                                cursor: 'pointer'
                              }}
                              w={'40px'}
                              h={'60px'}
                              position={'relative'}
                            >
                              <Image
                                src={'/images/top/pc/more_bell.png'}
                                alt={'もっと見る'}
                                layout={'fill'}
                                objectFit={'contain'}
                              />
                            </Box>
                          </Link>
                        </Flex>
                      </HStack>
                    </PcDisplayBlock>
                  </Box>
                </Box>
                <SpDisplayBlock>
                  <Flex
                    flex={1}
                    justify={'flex-end'}
                  >
                    <Link
                      href={getPageInfo('brand/bellepoc', {}).href}
                      passHref={true}
                    >
                      <Box
                        as={'a'}
                        _hover={{
                          textDecoration: 'none',
                          opacity: 0.8,
                          cursor: 'pointer'
                        }}
                        w={'100px'}
                        h={'50px'}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/sp/more_bell.png'}
                          alt={'もっと見る'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                    </Link>
                  </Flex>
                </SpDisplayBlock>
              </Box>
            </GridItem>
            <GridItem
              area={'rua'}
              borderLeft={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
              pb={{ lg: 4 }}
              mb={{ base: 4, lg: 4 }}
              mt={{ lg: 4 }}
            >
              <Flex
                flexDirection={'column'}
                pt={{ lg: 10 }}
                px={{ lg: 5 }}
              >
                <Box>
                  <Box
                    display={'flex'}
                    flexDirection={{ base: 'row-reverse', lg: 'row' }}
                    justifyContent={{ base: 'space-between', lg: 'flex-start' }}
                    alignItems={'center'}
                  >
                    <Box
                      position={'relative'}
                      w={{ base: '60px', lg: 20 }}
                      h={{ base: '60px', lg: 20 }}
                    >
                      <Image
                        src={'/images/top/number/05.png'}
                        alt={'03'}
                        layout={'fill'}
                        objectFit={'contain'}
                      />
                    </Box>
                    <Box>
                      <Text
                        as={'h3'}
                        fontWeight={'bold'}
                        fontSize={'24px'}
                        color={'ruamruamBrandSecondary.300'}
                        pl={{ base: 0, lg: 2 }}
                      >
                        Ruam Ruam
                      </Text>
                      <Text
                        as={'h3'}
                        pl={{ base: 0, lg: 2 }}
                        fontWeight={'bold'}
                        fontSize={'16px'}
                        color={'ruamruamBrandSecondary.300'}
                      >
                        アジアン手技 &times; ボディケア
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={{ base: 'flex', lg: 'block' }}
                  flexDirection={'column-reverse'}
                >
                  <Link
                    href={getPageInfo('brand/ruamruam', {}).href}
                    passHref={true}
                  >
                    <Box
                      as={'a'}
                    >
                      <Box
                        w={{ base: '100%', lg: 400 }}
                        pt={4}
                      >
                        <Image
                          src={'/images/top/store/ruam_store.jpg'}
                          alt={'RuamRuam 店舗'}
                          width={900}
                          height={600}
                        />
                      </Box>
                    </Box>
                  </Link>
                  <Box
                    w={{ lg: '400px' }}
                  >
                    <Text
                      textAlign={'justify'}
                      pb={4}
                      textStyle={'h5'}
                      pt={'6px'}
                    >
                      心身を解放してリセットする アジアン手技を提供します。プライベートな施術スペースで ハーブの香りに包まれ、身体も心もリセットするための時間をお過ごしください。
                    </Text>
                  </Box>
                  <Box>
                    <PcDisplayBlock>
                      <HStack
                        alignItems={'baseline'}
                      >
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/footcare.png'}
                            alt={'フットケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Box
                          w={10}
                          h={10}
                          position={'relative'}
                        >
                          <Image
                            src={'/images/top/labels/body_care.png'}
                            alt={'ボディケア'}
                            layout={'fill'}
                            objectFit={'contain'}
                          />
                        </Box>
                        <Flex
                          flex={1}
                          justify={'flex-end'}
                        >
                          <Link
                            href={getPageInfo('brand/ruamruam', {}).href}
                            passHref={true}
                          >
                            <Box
                              as={'a'}
                              _hover={{
                                textDecoration: 'none',
                                opacity: 0.8,
                                cursor: 'pointer'
                              }}
                              w={'40px'}
                              h={'60px'}
                              position={'relative'}
                            >
                              <Image
                                src={'/images/top/pc/more_ruam.png'}
                                alt={'もっと見る'}
                                layout={'fill'}
                                objectFit={'contain'}
                              />
                            </Box>
                          </Link>
                        </Flex>
                      </HStack>
                    </PcDisplayBlock>
                  </Box>
                </Box>
                <SpDisplayBlock>
                  <Flex
                    flex={1}
                    justify={'flex-end'}
                  >
                    <Link
                      href={getPageInfo('brand/ruamruam', {}).href}
                      passHref={true}
                    >
                      <Box
                        as={'a'}
                        _hover={{
                          textDecoration: 'none',
                          opacity: 0.8,
                          cursor: 'pointer'
                        }}
                        w={'100px'}
                        h={'50px'}
                        position={'relative'}
                      >
                        <Image
                          src={'/images/top/sp/more_ruam.png'}
                          alt={'もっと見る'}
                          layout={'fill'}
                          objectFit={'contain'}
                        />
                      </Box>
                    </Link>
                  </Flex>
                </SpDisplayBlock>
              </Flex>
            </GridItem>
            <GridItem
              area={'mov'}
              pt={4}
              px={{ lg: 10 }}
              display={{ base: 'none', lg: 'flex' }}
              borderBottom={{ base: '2px solid', lg: '0px' }}
              borderBottomColor={'rgba(85, 62, 38, 0.3)'}
            >
              <MovieArea/>
            </GridItem>
          </Grid>

          {/* news,map */}
          <Box
            pt={{ base: 8, lg: 4 }}
            pb={{ lg: 4 }}
            px={{ lg: 4 }}
            mb={{ base: 0, lg: '20px' }}
            borderTop={'2px solid rgba(85, 62, 38, 0.3)'}
            borderBottom={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
          >
            <Box
              display={{ base: 'block', lg: 'flex' }}
              justifyContent={'space-around'}
            >
              <Box
                display={'block'}
                flexWrap={'wrap'}
                pt={{ lg: 8 }}
                flex={1.2}
              >
                <SpDisplayBlock>
                  <Box
                    textAlign={'left'}
                  >
                    <HeadingImage
                      as={'h2'}
                      headingImage={'/images/top/heading/news.png'}
                      headingAlt={'ニュース'}
                      width={200}
                      height={45}
                    />
                  </Box>
                </SpDisplayBlock>
                <PcDisplayBlock>
                  <Box
                    textAlign={'left'}
                    pl={5}
                  >
                    <HeadingImage
                      as={'h2'}
                      headingImage={'/images/top/heading/news.png'}
                      headingAlt={'ニュース'}
                      width={200}
                      height={45}
                    />
                  </Box>
                </PcDisplayBlock>
                {customerInformations.items.map((customerInformation) =>
                  <Link
                    key={customerInformation.displayId}
                    href={getPageInfo('news/{id}', customerInformation).href}
                  >
                    <Box
                      as={'div'}
                      cursor={'pointer'}
                      _hover={{ opacity: 0.5 }}
                      borderBottom={'1px solid'}
                      borderColor={'gray.300'}
                      width={{ lg: '85%' }}
                      textAlign={'left'}
                    >
                      <ListItem
                        as={'h3'}
                        customerInformation={customerInformation}
                      />
                    </Box>
                  </Link>
                )}
                <Box
                  pb={{ base: 0, lg: 8 }}
                >
                  <LinkButton
                    text={'ニュース一覧'}
                    mt={6}
                    mx={{ base: 'auto', lg: 0 }}
                    w={{ base: '80%', lg: '40%' }}
                    linkUrl={getPageInfo('news', {})}
                    textAlign={{ base: 'center', lg: 'left' }}
                  />
                </Box>
              </Box>

              <Box
                flex={1}
                pt={8}
                mt={{ base: 8, lg: 0 }}
                borderLeft={{ lg: '2px solid rgba(85, 62, 38, 0.3)' }}
                borderTop={{ base: '2px solid rgba(85, 62, 38, 0.3)', lg: 'none' }}
                borderBottom={{ base: '2px solid rgba(85, 62, 38, 0.3)', lg: 'none' }}
                pl={{ lg: 10 }}
              >
                <Box
                  textAlign={'left'}
                >
                  <HeadingImage
                    mx={'auto'}
                    as={'h2'}
                    headingImage={'/images/top/heading/store.png'}
                    headingAlt={'店舗'}
                    width={180}
                    height={45}
                  />
                </Box>

                <JapanMap/>
                <Box
                  pb={8}
                >
                  <LinkButton
                    mx={'auto'}
                    display={'block'}
                    mt={6}
                    text={'店舗検索'}
                    w={{ base: '80%', lg: '50%' }}
                    linkUrl={getPageInfo('studio', {})}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            py={{ base: 8, lg: 6 }}
          >
            <SpDisplayBlock>
              <Box
                textAlign={'left'}
              >
                <HeadingImage
                  as={'h2'}
                  headingImage={'/images/top/heading/members_app.png'}
                  headingAlt={'メンバーズアプリ'}
                  width={264}
                  height={68}
                />

                <Text
                  textAlign={'justify'}
                  textStyle={'h5'}
                >
                  リラクグループ公式のアプリです。24時間いつでも空き情報の確認や、予約ができます。公式アプリを活用して、お身体のメンテナンスを始めませんか？
                </Text>
                <Text
                  textStyle={'h8'}
                  lineHeight={'1.5'}
                >
                  ※アプリの利用開始には店舗予約を通した会員登録が必要です。
                </Text>
              </Box>
              <Box
                textAlign={'center'}
                mt={5}
              >
                <Image
                  src={'/images/top_app.png'}
                  alt={'メンバーズアプリ'}
                  width={487}
                  height={513}
                />
              </Box>
              <Box
                textAlign={{ base: 'left', md: 'center' }}
                mx={4}
                mt={2}
              >
                <Text
                  fontWeight={'bold'}
                  textStyle={'h5'}
                  color={'brand.500'}
                  py={2}
                >
                  ダウンロードはこちらから
                </Text>
                <Text>
                  アプリをダウンロードしてご来店いただくとスムーズにご案内することができます。
                </Text>

                <Box
                  display={{ base: 'flex' }}
                  textAlign={'center'}
                  justifyContent={{ base: 'center', lg: 'left' }}
                  mt={{ lg: 5 }}
                >
                  <AppStoreLink/>
                </Box>
              </Box>
            </SpDisplayBlock>

            <PcDisplayBlock>
              <Flex
                justifyContent={'space-evenly'}
              >
                <Box>
                  <HeadingImage
                    as={'h2'}
                    headingImage={'/images/top/heading/members_app.png'}
                    headingAlt={'メンバーズアプリ'}
                    width={264}
                    height={65}
                  />
                  <Box
                    textAlign={'justify'}
                    textStyle={'h5'}
                    py={5}
                  >
                    <Text>
                      リラクグループ公式のアプリです。
                    </Text>
                    <Text>24時間いつでも空き情報の確認や、予約ができます。</Text>
                    <Text>公式アプリを活用して、お身体のメンテナンスを始めませんか？</Text>
                    <Text
                      pt={2}
                      textStyle={'h8'}
                      lineHeight={'1.5'}
                    >
                      ※アプリの利用開始には店舗予約を通した会員登録が必要です。
                    </Text>
                  </Box>
                  <Box
                    width={400}
                    mt={4}
                  >
                    <Image
                      src={'/images/top/pc/app_label.png'}
                      alt={'アプリダウンロード'}
                      width={600}
                      height={139}
                    />
                  </Box>
                </Box>
                <Flex
                  ml={5}
                >
                  <Box
                    textAlign={{ base: 'left', md: 'center' }}
                    bg={'rgba(115, 186, 26, 0.1)'}
                    w={'340px'}
                    my={20}
                    mt={5}
                    py={5}
                    px={8}
                  >
                    <Text
                      fontWeight={'bold'}
                      textStyle={'h5'}
                      color={'brand.500'}
                      py={2}
                    >
                      ダウンロードはこちらから
                    </Text>
                    <Text>
                      アプリをダウンロードしてご来店いただくとスムーズにご案内することができます。
                    </Text>

                    <Flex
                      textAlign={'center'}
                      justifyContent={'left'}
                      mt={5}
                    >
                      <AppStoreLink/>
                    </Flex>
                    <HStack
                      justifyContent={'space-around'}
                    >
                      <Image
                        src={'/images/top/pc/ios_qr.png'}
                        alt={'iOS用ダウンロード'}
                        width={100}
                        height={100}
                      />
                      <Image
                        src={'/images/top/pc/android_qr.png'}
                        alt={'GooglePlay用ダウンロード'}
                        width={100}
                        height={100}
                      />
                    </HStack>
                  </Box>
                  <Box
                    w={'180px'}
                  >
                    <Image
                      src={'/images/app.png'}
                      alt={'アプリダウンロード'}
                      width={456}
                      height={915}
                    />
                  </Box>
                </Flex>
              </Flex>
            </PcDisplayBlock>
          </Box>
        </Box>
        <SpDisplayBlock
          zIndex={'sticky'}
          position={'sticky'}
          bottom={0}
        >
          <ShopSearchFixedFooter/>
        </SpDisplayBlock>
      </Box>
    </>
  );
};

