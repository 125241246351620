

import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Text
} from '@chakra-ui/react';
import { useCallback } from 'react';

export type PrefectureAreaName = '東北' | '関東' | '中部' | '関西' | '中国' | '九州';

type MapButtonProps = {
  prefectureAreaName: PrefectureAreaName;
  border: BoxProps['border'];
  top: BoxProps['top'];
  left: BoxProps['left'];
  onClick?(text: string): void;
}

export const MapButton = ({
  prefectureAreaName,
  border,
  top,
  left,
  onClick,
}: MapButtonProps) => {
  const handleClick = useCallback(() => onClick && onClick(prefectureAreaName), [onClick, prefectureAreaName]);

  return (
    <Box
      as={'button'}
      position={'absolute'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      border={border}
      borderStyle={'solid'}
      textAlign={'center'}
      borderRadius={'50%'}
      cursor={'pointer'}
      width={'12.6%'}
      height={'12%'}
      top={top}
      left={left}
      fontSize={'1em'}
      fontWeight={'bold'}
      zIndex={1}
      borderColor={'brand.500'}
      color={'brand.500'}
      bg={'white'}
      _hover={{
        color: 'white',
        bg: 'brand.500'
      }}
      onClick={handleClick}
    >
      <Box>
        <Text pt={2}>
          {prefectureAreaName}
        </Text>
        <ArrowForwardIcon mt={-2}/>
      </Box>
    </Box>
  );
};
