import {
  AspectRatio,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Image } from '@/components/Image';

export type MovieAreaProps = {};

export const MovieArea = ({ }: MovieAreaProps) => {
  const [isThumbnail, setIsThumbnail] = useState(true);
  return (
    <Box
      flex={1}
      display={'block'}
      my={5}
      mb={{ base: 10, lg: 4 }}
    >
      {isThumbnail ? (
        <Box>
          <Image
            src={'/images/movie_thumbnail.png'}
            onClick={() => setIsThumbnail(false)}
            alt={'Re.Ra.Ku サムネイル'}
            width={640}
            height={360}
          />
        </Box>
      ) : (
        <Box>
          <AspectRatio
            maxW={'640px'}
            ratio={16 / 9}
          >
            <iframe
              src={'https://www.youtube.com/embed/bzJASJRFFE8?autoplay=1'}
              title={'Re.Ra.Ku ボディケア'}
              allowFullScreen
            />
          </AspectRatio>
        </Box>
      )}
    </Box>
  );
};
