import {
  Flex,
  Select,
  Text,
  Box,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { Input } from '@/components';
import { Image } from '@/components/Image';
import { useCountOfShops } from '@/features/shops/components/hooks/useCountOfShops';
import { useShopSearch } from '@/features/shops/components/hooks/useShopSearch';
import { SearchButton } from '@/features/shops/components/ui/ShopSearch/SearchButton';

export type PcShopSearchFormProps = {
  shopSearch: ReturnType<typeof useShopSearch>;
  onOpen: () => void;
  onClose: () => void;
};

export const PcShopSearchForm = ({
  shopSearch,
  onOpen,
  onClose,
}: PcShopSearchFormProps) => {
  const{
    isSearching,
    shopSearchForm,
    getShopSearchHandler,
    shopSearchConditions,
    isLocationSwitchOn,
    isCurrentLocationLoading
  } = shopSearch;

  const { register } = shopSearchForm;
  const { allPrefectures } = shopSearchConditions;

  const handleSearchShop = useMemo(() => {
    return getShopSearchHandler({
      onSuccess: () => {
        onClose();
      }
    });
  }, [getShopSearchHandler, onClose]);
  const { isLoadingCountOfShops, countOfShops } = useCountOfShops({ form: shopSearchForm });

  return (
    <form onSubmit={handleSearchShop}>
      <Flex
        fontWeight={'bold'}
        alignItems={'center'}
        mt={3}
        maxW={'1200px'}
        mx={'auto'}
      >
        <Box
          flexBasis={'15%'}
          color={'white'}
          display={'inline-flex'}
        >
          <Image
            src={'/images/top/pc/shop.svg'}
            alt={'店舗検索'}
            width={32}
            height={32}
          />
          <Text
            pl={2}
            pt={1}
            textStyle={{ base: 'h3', xl: 'h2' }}
          >
            店舗検索
          </Text>
        </Box>
        <Select
          {...register('prefectureId', {
            setValueAs: value => !value && value !== 0 ? null : Number(value),
          })}
          disabled={isLocationSwitchOn || isCurrentLocationLoading ? true : false}
          borderColor={'brand.500'}
          color={'gray.500'}
          height={'48px'}
          flexDirection={'column'}
          flexBasis={'25%'}
          mx={2}
          bg={'white'}
        >
          <option value={''}>{isLocationSwitchOn ? '現在地情報が選択されています' : '都道府県を選択してください'}</option>
          {allPrefectures.map(prefecture =>
            <option
              key={prefecture.id}
              value={prefecture.id}
            >
              {prefecture.name}
            </option>
          )}
        </Select>
        <Input
          flexBasis={'25%'}
          mx={2}
          placeholder={'地名・駅・店名など'}
          size={'lg'}
          borderColor={'brand.500'}
          bg={'white'}
          {...register('q')}
        />
        <SearchButton
          mx={2}
          flexBasis={'20%'}
          countOfShops={countOfShops}
          isSearching={isSearching}
          isLocationLoading={isCurrentLocationLoading}
          isLoadingCountOfShops={isLoadingCountOfShops}
          size={'lg'}
          fontSize={'md'}
          bg={'red.500'}
          border={'3px solid white'}
        />
        <Text
          as={'a'}
          mx={2}
          flexBasis={'15%'}
          onClick={onOpen}
          size={'lg'}
          fontSize={'md'}
          color={'white'}
          textDecoration={'underline'}
          _hover={{ cursor: 'pointer' }}
        >
          さらに絞り込む
        </Text>
      </Flex>
    </form>
  );
};
