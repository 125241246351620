import {
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Image } from '@/components/Image';
import { getPageInfo } from '@/config/pages';
import {
  MapButton,
  PrefectureAreaName
} from '@/features/top/components/ui/JapanMap/MapButton';

type JapanMapProps = {
  onClick?(text: string): void;
} & BoxProps;

export const JapanMap = ({
  onClick,
  ...rest
}: JapanMapProps) => {
  const router = useRouter();
  const [onLoaded, setOnLoaded] = useState<boolean>(false);
  const [baseFontSize, setBaseFontSize] = useState<number>(20);
  const [mapButtonBorder, setMapButtonBorder] = useState<BoxProps['border']>(3);
  const mapRef = useRef<HTMLDivElement>(null);

  const resizeFontSize = useCallback(() => {
    if (!mapRef.current) return;

    const { width } = mapRef.current.getBoundingClientRect();

    if (width > 1000) {
      setBaseFontSize(25);
      setMapButtonBorder(3);
    } else if (width > 800) {
      setBaseFontSize(18);
      setMapButtonBorder(3);
    } else if (width > 600) {
      setBaseFontSize(15);
      setMapButtonBorder(2);
    } else if (width > 400) {
      setBaseFontSize(13);
      setMapButtonBorder(1);
    } else {
      setBaseFontSize(12);
      setMapButtonBorder(1);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    window.addEventListener('resize', resizeFontSize, { signal: controller.signal });
    resizeFontSize();
    setOnLoaded(true);

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(async (prefectureAreaName: PrefectureAreaName) => {
    if (!onClick) {
      let prefectureAreaId: number;
      switch (prefectureAreaName) {
        case '東北':
          prefectureAreaId = 2;
          break;
        case '関東':
          prefectureAreaId = 3;
          break;
        case '中部':
          prefectureAreaId = 4;
          break;
        case '関西':
          prefectureAreaId = 5;
          break;
        case '中国':
          prefectureAreaId = 6;
          break;
        case '九州':
          prefectureAreaId = 8;
          break;
      }
      return await router.push(
        getPageInfo('studio/pref_area/{prefectureAreaId}', { id: prefectureAreaId, name: prefectureAreaName }).href
      );
    }
    onClick(prefectureAreaName);
  }, [onClick, router]);

  return (
    <Box
      {...rest}
      ref={mapRef}
    >
      <Box
        maxW={1400}
        position={'relative'}
        fontSize={baseFontSize}
      >
        <Image
          src={'/images/Japan_map.png'}
          alt={'日本地図'}
          layout={'responsive'}
          width={1000}
          height={1054}
          objectFit={'cover'}
        />

        {onLoaded && (
          <>
            <MapButton
              prefectureAreaName={'東北'}
              border={mapButtonBorder}
              top={'42%'}
              left={'83%'}
              onClick={handleClick}
            />

            <MapButton
              prefectureAreaName={'関東'}
              border={mapButtonBorder}
              top={'72%'}
              left={'68%'}
              onClick={handleClick}
            />

            <MapButton
              prefectureAreaName={'中部'}
              border={mapButtonBorder}
              top={'20%'}
              left={'40%'}
              onClick={handleClick}
            />

            <MapButton
              prefectureAreaName={'関西'}
              border={mapButtonBorder}
              top={'83%'}
              left={'39%'}
              onClick={handleClick}
            />

            <MapButton
              prefectureAreaName={'中国'}
              border={mapButtonBorder}
              top={'30%'}
              left={'20%'}
              onClick={handleClick}
            />

            <MapButton
              prefectureAreaName={'九州'}
              border={mapButtonBorder}
              top={'50%'}
              left={'3%'}
              onClick={handleClick}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
